





















import Vue from 'vue'
import { Prop, Component, Mixins, Watch } from 'vue-property-decorator'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
// @ts-ignore
import draggable from 'vuedraggable'
import { BeatStore } from '#/store/beat'
import { fetchAllBeatsLight, updateBeat } from '#/api/beat'

@Component({ components: { VLoadSpinner, draggable } })
export default class BeatsReorderPage extends Vue {
    beats: IBeatLight[] = []
    isLoading: boolean = true

    async _fetchAllBeatsLight() {
        this.beats = await fetchAllBeatsLight()
        this.isLoading = false
    }

    async onEnd(value: any) {
        const draggedItemIndex = value.newIndex
        // find the item in the array coming before and after the item just moved
        const positionOfItemBefore = this.beats[draggedItemIndex - 1] ? this.beats[draggedItemIndex - 1].order_position : null
        const positionOfItemAfter = this.beats[draggedItemIndex + 1].order_position
        let draggedItemNewPosition
        // if item was moved to index 0, we give it an order_position === to the order_position of the item coming right after, +1
        if (positionOfItemBefore === null) draggedItemNewPosition = positionOfItemAfter + 0.01
        else {
            // sum the order_positions of the item coming before and then one coming after and divide by 2, to find the middle
            draggedItemNewPosition = (positionOfItemBefore + positionOfItemAfter) / 2
        }
        // update value locally so that next drags will have correct values
        this.beats[draggedItemIndex].order_position = draggedItemNewPosition
        // patch
        await updateBeat({ id: this.beats[draggedItemIndex].id, order_position: draggedItemNewPosition })
    }

    created() {
        this._fetchAllBeatsLight()
    }
}
